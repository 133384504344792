import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const BgElem = styled.div`
  background: ${(props) => props.bgcolortest};

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    a:hover {
      background: white;
      p,
      h3,
      span,
      b,
      strong {
        color: ${(props) => props.bgcolortest};
      }
    }
  }
`;

const ItemElem = styled(Link)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 40px;
  cursor: pointer;
  height: 100%;
  text-decoration: none;

  * {
    color: white;
  }

  p {
    font: 15px / 20px "FS Regular";
  }

  h3 {
    font: 24px / 34px "FS Light";
    margin-bottom: 8px;

    span,
    b,
    strong {
      font-family: "Mrs Eaves Bold Italic";
      font-size: 29px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    p {
      font: 15px / 18px "FS Regular";
    }

    h3 {
      font: 29px / 42px "FS Light";
      margin-bottom: 12px;

      b,
      strong,
      span {
        font: 35px / 42px "Mrs Eaves Bold Italic";
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0 110px;

    > * {
      width: 100%;
      max-width: 463px;
      margin: 0 auto;
    }

    p {
      font: 24px / 30px "FS Regular";
    }

    h3 {
      font: 42px / 61px "FS Light";
      margin-bottom: 22px;

      b,
      strong,
      span {
        font: 51px / 61px "Mrs Eaves Bold Italic";
      }
    }
  }
`;

interface ColorItemProps {
  color: string;
  text: string;
}

const ColorItem: React.FC<ColorItemProps> = ({ itemColor, text, link }) => (
  <BgElem bgcolortest={itemColor}>
    <ItemElem
      bgcolortest={itemColor}
      dangerouslySetInnerHTML={{ __html: text }}
      to={"/" + link}
    ></ItemElem>
  </BgElem>
);

export default ColorItem;
