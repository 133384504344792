import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StoererElem = styled.a`
  display: block;
  width: 100%;
  padding: 9px 15px 11px;
  background: #f2d35b;
  text-align: center;
  cursor: default;
  text-decoration: none;
  pointer-events: none;

  &.link {
    cursor: pointer;
    pointer-events: auto;
  }

  br {
    display: inline;
    &: after;
  }

  h2 {
    font: 20px / 24px "FS Medium";
    margin-bottom: 7px;
  }

  h3 {
    font: 16px / 21px "FS Regular";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: none;

    &.safari {
      display: block;
      max-width: 1920px;
      margin: 0 auto;
      padding: 20px 25px 20px;

      h2 {
        font: 29px / 35px "FS Medium";
        margin-bottom: 18px;
      }
      h3 {
        font: 19px /23px "FS Medium";
      }
    }
  }
`;

const StoererMobile = ({ content, link }) => {
  const stoererLink = () => {
    if (link && link.length > 0) {
      if (link[0].linkUrl) {
        return link[0].linkUrl;
      } else if (link[0].datei && link[0].datei.length > 0) {
        return link[0].datei[0].url;
      } else if (link[0].telefon) {
        return "tel:" + link[0].telefon;
      } else if (link[0].telefon) {
        return "mailto:" + link[0].email;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {typeof window != "undefined" ? (
        <>
          {navigator.userAgent.indexOf("Safari") != -1 &&
          navigator.userAgent.indexOf("Chrome") == -1 &&
          content ? (
            <StoererElem
              dangerouslySetInnerHTML={{ __html: content }}
              className={stoererLink() ? "safari link" : "safari"}
              href={stoererLink() ? stoererLink() : ""}
              target={stoererLink() ? "_blank" : "_self"}
            ></StoererElem>
          ) : content ? (
            <StoererElem
              dangerouslySetInnerHTML={{ __html: content }}
              className={stoererLink() ? "link" : ""}
              href={stoererLink() ? stoererLink() : ""}
              target={stoererLink() ? "_blank" : "_self"}
            ></StoererElem>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default StoererMobile;
