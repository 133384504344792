import React from "react";
import styled from "styled-components";
import { breakpoints, maxWidth } from "../../styles/variables";

const GridDiv = styled.div`
  width: 100%;

  .image {
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 305px;
    max-width: ${maxWidth}px;
    margin: 0 auto;

    > div:nth-of-type(1) {
      order: 1;
    }
    > div:nth-of-type(2) {
      order: 2;
    }
    > div:nth-of-type(3) {
      order: 4;
    }
    > div:nth-of-type(4) {
      order: 3;
    }
    > div:nth-of-type(5) {
      order: 5;
    }
    > div:nth-of-type(6) {
      order: 6;
    }
    > div:nth-of-type(7) {
      order: 8;
    }
    > div:nth-of-type(8) {
      order: 7;
    }
    > div:nth-of-type(9) {
      order: 9;
    }
    > div:nth-of-type(10) {
      order: 10;
    }
    > div:nth-of-type(11) {
      order: 12;
    }
    > div:nth-of-type(12) {
      order: 11;
    }
    > div:nth-of-type(13) {
      order: 13;
    }
    > div:nth-of-type(14) {
      order: 14;
    }
    > div:nth-of-type(15) {
      order: 16;
    }
    > div:nth-of-type(16) {
      order: 15;
    }
    > div:nth-of-type(17) {
      order: 17;
    }
    > div:nth-of-type(18) {
      order: 18;
    }
    > div:nth-of-type(19) {
      order: 20;
    }
    > div:nth-of-type(20) {
      order: 19;
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-auto-rows: 405px;
  }
`;

const ColorGrid: React.FC = ({ children }) => <GridDiv>{children}</GridDiv>;

export default ColorGrid;
