import { graphql } from "gatsby";
import React from "react";
import AbschlussElemente from "../components/AbschlussElemente";
import BackgroundWrapper from "../components/BackgroundWrapper";
import ColorGrid from "../components/ColorGrid/ColorGrid";
import ColorItem from "../components/ColorGrid/ColorItem";
import { getColor } from "../components/helpers/getColor";
import Landing from "../components/Landing";
import StoererMobile from "../components/StoererMobile";
import PostLandingText from "../components/Text/PostLandingText";
import DefaultLayout from "../layouts/default";

import { imgix } from "../styles/variables";
import {
  getImgix,
  getImgixImg,
  getImgixSrcset,
} from "../components/helpers/getImgix";
import ImgixImg from "../components/helpers/ImgixImg";

interface HomeProps {
  data: {
    cms: {
      entry: {
        title: string;
        id: number;
        siteId: number;
        metaBeschreibung: string;
        landingBild: [
          {
            url: string;
          }
        ];
        landingTitelStartseite: string;
        postLandingText: string;
        startseitenGrid: [
          {
            text: string;
            bild: [
              {
                url: string;
              }
            ];
          }
        ];
        abschlussElemente: [
          {
            bild: [
              {
                url: string;
              }
            ];
            zitat: string;
            autor: string;
            buttonText: string;
            buttonLinkUrl: string;
            buttonLinkDatei: [
              {
                url: string;
              }
            ];
            buttonLinkTelefon: string;
            buttonLinkEmail: string;
            buttonIcon: string;
            inhaltstext: string;
          }
        ];
      };
    };
  };
}

export const HomeQuery = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId

        ... on CMS_main_startseite_Entry {
          metaBeschreibung
          landingBild {
            url
          }
          landingTitelStartseite
          stoerer
          stoererPosition
          stoererMobile
          stoererLink {
            ... on CMS_stoererLink_linkUrl_BlockType {
              linkUrl
            }
            ... on CMS_stoererLink_linkDatei_BlockType {
              datei {
                url
              }
            }
            ... on CMS_stoererLink_linkTelefon_BlockType {
              telefon
            }
            ... on CMS_stoererLink_linkEmail_BlockType {
              email
            }
          }
          postLandingText
          startseitenGrid {
            ... on CMS_startseitenGrid_reihe_BlockType {
              text
              bild {
                url
              }
              reihenLink {
                url
                uri
              }
            }
          }
          abschlussElemente {
            ... on CMS_abschlussElemente_bild_BlockType {
              bild {
                url
              }
            }
            ... on CMS_abschlussElemente_zitat_BlockType {
              zitat
              autor
            }
            ... on CMS_abschlussElemente_button_BlockType {
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstext_BlockType {
              inhaltstext
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstextBgColor_BlockType {
              titel
              text
            }
          }
        }
      }
    }
  }
`;

const Home: React.FC<HomeProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      description={data.cms.entry.metaBeschreibung}
      title={data.cms.entry.title}
    >
      {data.cms.entry.stoererMobile && data.cms.entry.stoererLink ? (
        <StoererMobile
          content={data.cms.entry.stoererMobile}
          link={data.cms.entry.stoererLink}
        />
      ) : data.cms.entry.stoererMobile ? (
        <StoererMobile content={data.cms.entry.stoererMobile} link={""} />
      ) : (
        ""
      )}

      {data.cms.entry.landingBild && data.cms.entry.landingBild.length > 0 ? (
        <Landing
          title={data.cms.entry.landingTitelStartseite}
          image={data.cms.entry.landingBild[0].url}
          stoerer={data.cms.entry.stoerer}
          alignment={data.cms.entry.stoererPosition}
          link={data.cms.entry.stoererLink ? data.cms.entry.stoererLink : ""}
          startseite={true}
        />
      ) : (
        ""
      )}

      {data.cms.entry.postLandingText ? (
        <PostLandingText text={data.cms.entry.postLandingText} />
      ) : (
        ""
      )}
      {data.cms.entry.startseitenGrid &&
      data.cms.entry.startseitenGrid.length > 0 ? (
        <BackgroundWrapper>
          <ColorGrid>
            {data.cms.entry.startseitenGrid.map((item, index) => (
              <React.Fragment key={index}>
                <div className="image">
                  <ImgixImg
                    src={item.bild[0].url}
                    imgixParams={imgix.halfImage}
                  />
                </div>
                <ColorItem
                  itemColor={getColor(data.cms.entry.siteId)}
                  text={item.text}
                  link={item.reihenLink[0].uri}
                />
              </React.Fragment>
            ))}
          </ColorGrid>
        </BackgroundWrapper>
      ) : (
        ""
      )}

      {data.cms.entry.abschlussElemente.length > 0 ? (
        <AbschlussElemente
          content={data.cms.entry.abschlussElemente}
          siteId={data.cms.entry.siteId}
        />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default Home;
